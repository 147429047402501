@use "sass:math";

.experience-homepageredesignassets-assets-herobanner {
    + .experience-homepageredesignassets-layouts-clubfinder {
        position: relative;
        margin-top: -150px;

        @include mq($not-mobile-tablet-pt) {
            margin-top: -124px;
        }
    }

    .banner__content {
        padding-bottom: 143px;
    }

    @include mq($not-mobile-tablet-pt) {
        .banner__content {
            padding-bottom: 70px;
            pointer-events: none;
            position: relative;
            z-index: 2;
        }

        .banner__link,
        .banner__link-extra {
            pointer-events: all;
        }
    }
}

.content-page-banner {
    &.next-component-up {
        + .experience-homepageredesignassets-layouts-clubfinder {
            .club-finder {
                margin-top: 0;
            }
        }
    }
}

.club-finder {
    margin: 20px auto;
    padding: 0 16px;
    position: relative;

    @include mq($not-mobile-tablet-pt) {
        display: flex;
        max-width: 1200px;
        padding: 0 40px;
    }

    .suggestions-flyout {
        top: 62px;
    }

    .result-content {
        padding: 11px 22px 6px;
        border: 0;

        @include mq($not-mobile-tablet-pt) {
            display: flex;
            flex-wrap: wrap;
            gap: 0 40px;
            padding-bottom: 0;
            padding-top: 19px;
        }

        .icon {
            display: none;
        }
    }

    .result-wrapper {
        border: 2px solid $wild-sand;
    }

    .result-anchor {
        font-weight: normal;
        padding: 0 0 2px;
        font-size: 16px;
    }

    .result-label {
        color: $nero;
        font-family: $heading-pro-treble-regular;
        font-size: 14px;
        margin-bottom: 6px;
        text-transform: capitalize;
    }

    .result-content__cities,
    .result-content__clubs {
        @include mq($not-mobile-tablet-pt) {
            flex: 0 1 auto;
            max-width: 50%;
            min-width: 23%;
            padding-right: 10px;
        }
    }

    .result-content__cities + .result-content__clubs {
        margin-top: 8px;

        @include mq($not-mobile-tablet-pt) {
            margin-top: 0;
        }
    }

    .result-content__amount {
        border-top: 2px solid $wild-sand;
        display: block;
        margin: 19px -22px 0;
        padding: 4px 22px;
        width: calc(100% + 44px);

        a {
            font-size: 13px;
            text-transform: none;
        }
    }

    .club-finder__search-input {
        border: 2px solid $safety-orange;
        box-sizing: border-box;
        font-size: 16px;
        height: 64px;
        padding: 22px;
        width: 100%;

        &:focus {
            background-color: $white;
            border-color: $safety-orange;
        }

        @include mq($not-mobile-tablet-pt) {
            max-width: 690px;
        }
    }

    @include mq($mobile-xs) {
        padding: 0 8px;
    }
}

.club-finder__content {
    background-color: $white;
    box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 29%);
    padding: 36px 24px 15px;
    width: 100%;

    @include mq($not-mobile-tablet-pt) {
        padding: 40px 56px 24px;
    }

    @include mq($mobile-xs) {
        padding: 30px 16px 15px;
    }
}

.club-finder__content-inner {
    @include clearfix;
}

.club-finder__title {
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center;

    @include mq($not-mobile-tablet-pt) {
        float: left;
        margin-bottom: 2px;
        max-width: 33%;
        text-align: left;
    }
}

.club-finder__description {
    font-family: $heading-pro-double-regular;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 6px;
    text-align: center;

    p,
    a {
        font-size: inherit;
        line-height: inherit;
        text-transform: none;
    }

    p {
        margin: 0;
    }

    a {
        color: $safety-orange;
    }

    @include mq($not-mobile-tablet-pt) {
        float: left;
        max-width: 33%;
    }
}

.club-finder__search-block {
    position: relative;

    @include mq($not-mobile-tablet-pt) {
        float: right;
        width: 67%;
    }
}

.club-finder__search-button {
    background-color: transparent;
    border-radius: 50%;
    border: 0;
    font-size: 25px;
    height: 38px;
    line-height: 36px;
    position: absolute;
    right: 21px;
    top: 13px;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;
    width: 38px;
    cursor: default;
}

.club-finder__search-button--active {
    cursor: pointer;

    @include mq($hover) {
        &:hover {
            background-color: $purple-heart;
            color: $white;
        }
    }
}

.club-finder__links {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    gap: 3px 12px;
    padding-top: 20px;
    overflow: auto;
    @include scrollbar;

    @include mq($not-mobile-tablet-pt) {
        flex-wrap: nowrap;
        gap: 16px;
        padding-top: 32px;
    }
}

.club-finder__link-wrapper {
    flex: 1 1 calc(50% - 6px);
    max-width: calc(50% - 6px);

    &:empty {
        display: none;
    }

    @include mq($not-mobile) {
        flex: 1 1 calc(33% - 6px);
        max-width: calc(33% - 6px);
    }

    @include mq($not-mobile-tablet-pt) {
        flex: 0 1 calc(16.67% - 13px);
    }
}

.club-finder__link {
    border-color: $anthracite;
    color: $nero;
    font-size: 12px;
    overflow: hidden;
    padding: 3px 8px;
    text-overflow: ellipsis;
    transition: background-color 0.2s ease-in;
    white-space: nowrap;
    width: 100%;

    @include mq($hover) {
        &:hover {
            border-color: $purple-heart;
        }
    }

    @include mq($not-mobile-tablet-pt) {
        max-width: 156px;
    }
}
