/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.experience-homepageredesignassets-assets-herobanner + .experience-homepageredesignassets-layouts-clubfinder {
  position: relative;
  margin-top: -150px;
}
@media only screen and (min-width: 901px) {
  .experience-homepageredesignassets-assets-herobanner + .experience-homepageredesignassets-layouts-clubfinder {
    margin-top: -124px;
  }
}
.experience-homepageredesignassets-assets-herobanner .banner__content {
  padding-bottom: 143px;
}
@media only screen and (min-width: 901px) {
  .experience-homepageredesignassets-assets-herobanner .banner__content {
    padding-bottom: 70px;
    pointer-events: none;
    position: relative;
    z-index: 2;
  }
  .experience-homepageredesignassets-assets-herobanner .banner__link,
  .experience-homepageredesignassets-assets-herobanner .banner__link-extra {
    pointer-events: all;
  }
}

.content-page-banner.next-component-up + .experience-homepageredesignassets-layouts-clubfinder .club-finder {
  margin-top: 0;
}

.club-finder {
  margin: 20px auto;
  padding: 0 16px;
  position: relative;
}
@media only screen and (min-width: 901px) {
  .club-finder {
    display: flex;
    max-width: 1200px;
    padding: 0 40px;
  }
}
.club-finder .suggestions-flyout {
  top: 62px;
}
.club-finder .result-content {
  padding: 11px 22px 6px;
  border: 0;
}
@media only screen and (min-width: 901px) {
  .club-finder .result-content {
    display: flex;
    flex-wrap: wrap;
    gap: 0 40px;
    padding-bottom: 0;
    padding-top: 19px;
  }
}
.club-finder .result-content .icon {
  display: none;
}
.club-finder .result-wrapper {
  border: 2px solid #ece9e8;
}
.club-finder .result-anchor {
  font-weight: normal;
  padding: 0 0 2px;
  font-size: 16px;
}
.club-finder .result-label {
  color: #2d2d2d;
  font-family: "HeadingProTreble-Regular", sans-serif;
  font-size: 14px;
  margin-bottom: 6px;
  text-transform: capitalize;
}
@media only screen and (min-width: 901px) {
  .club-finder .result-content__cities,
  .club-finder .result-content__clubs {
    flex: 0 1 auto;
    max-width: 50%;
    min-width: 23%;
    padding-right: 10px;
  }
}
.club-finder .result-content__cities + .result-content__clubs {
  margin-top: 8px;
}
@media only screen and (min-width: 901px) {
  .club-finder .result-content__cities + .result-content__clubs {
    margin-top: 0;
  }
}
.club-finder .result-content__amount {
  border-top: 2px solid #ece9e8;
  display: block;
  margin: 19px -22px 0;
  padding: 4px 22px;
  width: calc(100% + 44px);
}
.club-finder .result-content__amount a {
  font-size: 13px;
  text-transform: none;
}
.club-finder .club-finder__search-input {
  border: 2px solid #fe7000;
  box-sizing: border-box;
  font-size: 16px;
  height: 64px;
  padding: 22px;
  width: 100%;
}
.club-finder .club-finder__search-input:focus {
  background-color: #fff;
  border-color: #fe7000;
}
@media only screen and (min-width: 901px) {
  .club-finder .club-finder__search-input {
    max-width: 690px;
  }
}
@media only screen and (max-width: 359px) {
  .club-finder {
    padding: 0 8px;
  }
}

.club-finder__content {
  background-color: #fff;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.29);
  padding: 36px 24px 15px;
  width: 100%;
}
@media only screen and (min-width: 901px) {
  .club-finder__content {
    padding: 40px 56px 24px;
  }
}
@media only screen and (max-width: 359px) {
  .club-finder__content {
    padding: 30px 16px 15px;
  }
}

.club-finder__content-inner {
  *zoom: 1;
}
.club-finder__content-inner::before, .club-finder__content-inner::after {
  content: "";
  display: table;
}
.club-finder__content-inner::after {
  clear: both;
}

.club-finder__title {
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}
@media only screen and (min-width: 901px) {
  .club-finder__title {
    float: left;
    margin-bottom: 2px;
    max-width: 33%;
    text-align: left;
  }
}

.club-finder__description {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 6px;
  text-align: center;
}
.club-finder__description p,
.club-finder__description a {
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
}
.club-finder__description p {
  margin: 0;
}
.club-finder__description a {
  color: #fe7000;
}
@media only screen and (min-width: 901px) {
  .club-finder__description {
    float: left;
    max-width: 33%;
  }
}

.club-finder__search-block {
  position: relative;
}
@media only screen and (min-width: 901px) {
  .club-finder__search-block {
    float: right;
    width: 67%;
  }
}

.club-finder__search-button {
  background-color: transparent;
  border-radius: 50%;
  border: 0;
  font-size: 25px;
  height: 38px;
  line-height: 36px;
  position: absolute;
  right: 21px;
  top: 13px;
  transition: color 0.2s ease-in, background-color 0.2s ease-in;
  width: 38px;
  cursor: default;
}

.club-finder__search-button--active {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .club-finder__search-button--active:hover {
    background-color: #6131ad;
    color: #fff;
  }
}

.club-finder__links {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  gap: 3px 12px;
  padding-top: 20px;
  overflow: auto;
  scrollbar-color: #c5c5c5 transparent;
  scrollbar-width: thin;
}
.club-finder__links::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.club-finder__links::-webkit-scrollbar-track {
  background-color: transparent;
}
.club-finder__links::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
}
@media only screen and (min-width: 901px) {
  .club-finder__links {
    flex-wrap: nowrap;
    gap: 16px;
    padding-top: 32px;
  }
}

.club-finder__link-wrapper {
  flex: 1 1 calc(50% - 6px);
  max-width: calc(50% - 6px);
}
.club-finder__link-wrapper:empty {
  display: none;
}
@media only screen and (min-width: 768px) {
  .club-finder__link-wrapper {
    flex: 1 1 calc(33% - 6px);
    max-width: calc(33% - 6px);
  }
}
@media only screen and (min-width: 901px) {
  .club-finder__link-wrapper {
    flex: 0 1 calc(16.67% - 13px);
  }
}

.club-finder__link {
  border-color: #bdbdbd;
  color: #2d2d2d;
  font-size: 12px;
  overflow: hidden;
  padding: 3px 8px;
  text-overflow: ellipsis;
  transition: background-color 0.2s ease-in;
  white-space: nowrap;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  .club-finder__link:hover {
    border-color: #6131ad;
  }
}
@media only screen and (min-width: 901px) {
  .club-finder__link {
    max-width: 156px;
  }
}